import {getDictGroups} from '@/api/login.js'

const dict_params = {
    dictGroup:"TOTYPE_,PRODUCT_,COTYPE_,ACCOUNT_,operator_group,sellType,MEALTYPE_,algorithm_group,packageType,defaultStrategy,MergeCode,secondCaterogy_lt,secondCaterogy_yd,interfaceName,agent_business_type,audience_object,interfaceList,platform_protocol,coupon_type",
    type:"1"
}
const dictGroup = {
    state: {
        TOTYPE_ : [],   //业务类型
        PRODUCT_ : [],  //产品类型
        COTYPE_ : [],   //合作类型
        ACCOUNT_ : [],  //运营商
        operator_group : [], //web定义的 运营商
        sellType : [], //web定义的 套餐类型
        MEALTYPE_: [],  //套餐类型
        algorithm_group:[], //web定义的限速算法

        packageType:[],
        defaultStrategyList:[],
		mergeCode:[],
		secondCategory_yd:[],
		secondCategory_lt:[],
        interfaceName:[],
        agent_business_type:[],
        audience_object:[],
        interfaceList:[],
        platform_protocol:[],
        coupon_type:[]
    },
    getters: {
        TOTYPE_ : state => state.TOTYPE_,
        PRODUCT_ : state => state.PRODUCT_,
        COTYPE_ : state => state.COTYPE_,
        ACCOUNT_ : state => state.ACCOUNT_,
        operator_group : state => state.operator_group,
        sellType : state => state.sellType,
        MEALTYPE_ : state => state.MEALTYPE_,
        algorithm_group : state => state.algorithm_group,
        
        packageType:state=>state.packageType,//套餐类型
		defaultStrategyList:state=>state.defaultStrategyList,//虚值策略
		mergeCode:state=>state.mergeCode,//合并码
		secondCategory_yd:state=>state.secondCategory_yd, //二级运营商类别移动
		secondCategory_lt:state=>state.secondCategory_lt, //二级运营商类别联通
        interfaceName:state=>state.interfaceName,
        agent_business_type:state=>state.agent_business_type,
        audience_object:state=>state.audience_object,
        interfaceList:state=>state.interfaceList,
        platform_protocol:state=>state.platform_protocol,
        coupon_type:state=>state.coupon_type
    },
    mutations: {
        SET_DICGROUP : (state, data) => {
            state.TOTYPE_ = data.TOTYPE_
            state.PRODUCT_ = data.PRODUCT_
            state.COTYPE_ = data.COTYPE_ 
            state.ACCOUNT_ = data.ACCOUNT_ 
            state.operator_group = data.operator_group 
            state.sellType = data.sellType 
            state.MEALTYPE_ = data.MEALTYPE_ 
            state.algorithm_group = data.algorithm_group 
            
            
            state.packageType = data.packageType
			state.defaultStrategyList = data.defaultStrategy
			state.mergeCode = data.MergeCode
			state.secondCategory_yd = data.secondCaterogy_yd
            state.secondCategory_lt = data.secondCaterogy_lt
            state.interfaceName  =  data.interfaceName
            state.agent_business_type = data.agent_business_type
            state.audience_object = data.audience_object
            state.interfaceList = data.interfaceList
            state.platform_protocol = data.platform_protocol
            state.coupon_type = data.coupon_type
            // console.log(data.coupon_type)
        }
    },
    actions: {
        getDictGroups({commit}, res){
            return new Promise((resolve) => {
                getDictGroups(dict_params).then((data)=>{
					commit('SET_DICGROUP', data)
					resolve()
				})

            })
        },
        
    }
}

export default dictGroup